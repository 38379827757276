var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('media-library-renderless', {
    ref: "mediaLibraryRenderless",
    attrs: {
      "initial-value": _vm.initialValue,
      "validation-errors": _vm.validationErrors,
      "route-prefix": _vm.routePrefix,
      "validation-rules": _vm.validationRules,
      "translations": _vm.translations,
      "before-upload": _vm.beforeUpload,
      "after-upload": _vm.afterUpload,
      "multiple": _vm.multiple,
      "name": _vm.name,
      "max-items": _vm.computedMaxItems,
      "max-size-for-preview-in-bytes": _vm.maxSizeForPreviewInBytes,
      "vapor": _vm.vapor,
      "vapor-signed-storage-url": _vm.vaporSignedStorageUrl,
      "upload-domain": _vm.uploadDomain,
      "with-credentials": _vm.withCredentials,
      "headers": _vm.headers
    },
    on: {
      "changed": function changed($event) {
        return _vm.$emit('change', $event);
      },
      "is-ready-to-submit-change": function isReadyToSubmitChange($event) {
        return _vm.$emit('is-ready-to-submit-change', $event);
      },
      "has-uploads-in-progress-change": function hasUploadsInProgressChange($event) {
        return _vm.$emit('has-uploads-in-progress-change', $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var state = _ref.state,
          getImgProps = _ref.getImgProps,
          _getNameInputProps = _ref.getNameInputProps,
          _getNameInputListeners = _ref.getNameInputListeners,
          _getNameInputErrors = _ref.getNameInputErrors,
          _getCustomPropertyInputProps = _ref.getCustomPropertyInputProps,
          _getCustomPropertyInputListeners = _ref.getCustomPropertyInputListeners,
          _getCustomPropertyInputErrors = _ref.getCustomPropertyInputErrors,
          getDropZoneProps = _ref.getDropZoneProps,
          getDropZoneListeners = _ref.getDropZoneListeners,
          getFileInputProps = _ref.getFileInputProps,
          getFileInputListeners = _ref.getFileInputListeners,
          removeMedia = _ref.removeMedia,
          replaceMedia = _ref.replaceMedia,
          getErrors = _ref.getErrors,
          clearObjectErrors = _ref.clearObjectErrors,
          clearInvalidMedia = _ref.clearInvalidMedia;
        return _c('div', {}, [_c('icons'), _c('div', {
          staticClass: "media-library",
          class: [_vm.multiple ? 'media-library-multiple' : 'media-library-single', state.media.length == 0 ? 'media-library-empty' : 'media-library-filled']
        }, [_c('list-errors', {
          attrs: {
            "invalid-media": state.invalidMedia,
            "top-level-errors": _vm.validationErrors[_vm.name]
          },
          on: {
            "cleared": function cleared($event) {
              return clearInvalidMedia();
            }
          }
        }), state.media && state.media.length ? _c('div', {
          staticClass: "media-library-items"
        }, _vm._l(state.media, function (object) {
          return _c('div', {
            key: object.attributes.uuid,
            staticClass: "media-library-item"
          }, [_c('thumb', {
            attrs: {
              "uploadInfo": object.upload,
              "validation-rules": _vm.validationRules,
              "img-props": getImgProps(object)
            },
            on: {
              "replaced": function replaced($event) {
                return replaceMedia(object, $event);
              }
            }
          }), _c('div', {
            staticClass: "media-library-properties"
          }, [getErrors(object).length ? _c('item-errors', {
            attrs: {
              "object-errors": getErrors(object)
            },
            on: {
              "back": function back($event) {
                return clearObjectErrors(object);
              }
            }
          }) : [_vm._t("properties", function () {
            return [object.attributes.extension ? _c('div', {
              staticClass: "media-library-property"
            }, [_vm._v(" " + _vm._s(object.attributes.extension.toUpperCase()) + " ")]) : _vm._e(), object.attributes.size ? _c('div', {
              staticClass: "media-library-property"
            }, [_vm._v(" " + _vm._s((object.attributes.size / 1024).toFixed(2)) + " KB ")]) : _vm._e()];
          }, {
            "object": object
          }), _vm._t("fields", null, {
            "object": object,
            "getCustomPropertyInputProps": function getCustomPropertyInputProps(propertyName) {
              return _getCustomPropertyInputProps(object, propertyName);
            },
            "getCustomPropertyInputListeners": function getCustomPropertyInputListeners(propertyName) {
              return _getCustomPropertyInputListeners(object, propertyName);
            },
            "getCustomPropertyInputErrors": function getCustomPropertyInputErrors(propertyName) {
              return _getCustomPropertyInputErrors(object, propertyName);
            },
            "getNameInputProps": function getNameInputProps() {
              return _getNameInputProps(object);
            },
            "getNameInputListeners": function getNameInputListeners() {
              return _getNameInputListeners(object);
            },
            "getNameInputErrors": function getNameInputErrors() {
              return _getNameInputErrors(object);
            }
          }), _vm.can_remove ? _c('div', {
            staticClass: "media-library-property"
          }, [_c('button', {
            staticClass: "media-library-text-link",
            attrs: {
              "type": "button",
              "dusk": "remove"
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return removeMedia(object);
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.window.mediaLibraryTranslations.remove) + " ")])]) : _vm._e()]], 2)], 1);
        }), 0) : _vm._e(), _c('hidden-fields', {
          attrs: {
            "name": _vm.name,
            "media-state": state.media
          }
        }), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.computedMaxItems || state.media.length < _vm.computedMaxItems,
            expression: "!computedMaxItems || state.media.length < computedMaxItems"
          }],
          staticClass: "media-library-uploader"
        }, [_c('uploader', _vm._g(_vm._b({
          attrs: {
            "multiple": _vm.multiple,
            "add": "",
            "file-type-help-text": _vm.fileTypeHelpText
          }
        }, 'uploader', Object.assign({}, getDropZoneProps(), getFileInputProps()), false), Object.assign({}, getDropZoneListeners(), getFileInputListeners())))], 1)], 1)], 1);
      }
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }