var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('b-row', {
    ref: "row"
  }, [_c('b-col', {
    staticClass: "mb-50",
    attrs: {
      "cols": "12"
    }
  }, [[_c('label', [_vm._v("File:")]), _c('div', {
    staticClass: "d-flex align-items-start justify-content-between"
  }, [_c('div', {
    staticClass: "flex-grow-1"
  }, [_c('b-form-file', {
    attrs: {
      "placeholder": "Choose a file or drop it here...",
      "drop-placeholder": "Drop file here..."
    },
    on: {
      "input": function input($event) {
        return _vm.onFileChange($event);
      }
    }
  }), _vm.fields.file ? _c('b-card-text', {
    staticClass: "py-5px"
  }, [_vm._v(" File: "), _c('strong', [_vm._v(_vm._s(_vm.fields.name))])]) : _vm._e(), _c('validation-provider', {
    staticClass: "mt-2",
    attrs: {
      "tag": "div",
      "vid": "title",
      "name": "title",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          attrs: {
            "label": "Title"
          }
        }, [_c('b-form-input', {
          attrs: {
            "placeholder": "Title"
          },
          model: {
            value: _vm.fields.title,
            callback: function callback($$v) {
              _vm.$set(_vm.fields, "title", $$v);
            },
            expression: "fields.title"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])], 1)];
      }
    }])
  })], 1), _vm._t("delete_fileRep")], 2)], _vm.loadingFile ? _c('div', {
    staticClass: "loading-overlay"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('strong', [_vm._v("Loading...")]), _c('b-spinner', {
    staticClass: "ml-2"
  })], 1)]) : _vm._e()], 2), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr')])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }