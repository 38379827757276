var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "m-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-start mb-1 mb-md-0",
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('label', [_vm._v("Show")]), _c('v-select', {
    staticClass: "per-page-selector d-inline-block mx-50",
    attrs: {
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
      "options": _vm.perPageOptions,
      "clearable": false
    },
    model: {
      value: _vm.perPage,
      callback: function callback($$v) {
        _vm.perPage = $$v;
      },
      expression: "perPage"
    }
  }), _c('label', [_vm._v("entries")])], 1), _c('b-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-end"
  }, [_c('b-form-input', {
    staticClass: "d-inline-block mr-1",
    attrs: {
      "placeholder": "Search..."
    },
    model: {
      value: _vm.searchQuery,
      callback: function callback($$v) {
        _vm.searchQuery = $$v;
      },
      expression: "searchQuery"
    }
  }), _vm.canCreate ? _c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": _vm.addRoute
    }
  }, [_c('span', {
    staticClass: "text-nowrap text-capitalize"
  }, [_vm._v(_vm._s(_vm.addLabel))])]) : _vm._e(), _vm.currentActions.can_import || _vm.currentActions.can_export ? _c('b-dropdown', {
    staticClass: "ml-1",
    attrs: {
      "right": !_vm.$store.state.appConfig.isRTL,
      "text": "Tools",
      "variant": "primary"
    }
  }, [_vm.currentActions.can_import ? _c('b-dropdown-item', [_c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Import")])]) : _vm._e(), _vm.currentActions.can_export ? _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.exportTable.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Export")])]) : _vm._e(), _vm.currentActions.can_export ? _c('b-dropdown-item', {
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.openExportOptions.apply(null, arguments);
      }
    }
  }, [_c('span', {
    staticClass: "align-middle ml-50"
  }, [_vm._v("Export Options")])]) : _vm._e()], 1) : _vm._e()], 1)])], 1)], 1), _c('b-table', {
    ref: "refTable",
    staticClass: "position-relative",
    attrs: {
      "items": _vm.items,
      "responsive": "",
      "fields": _vm.shownHeaders,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "show-empty": "",
      "empty-text": "No matching records found",
      "sort-desc": _vm.isSortDirDesc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.isSortDirDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(user)",
      fn: function fn(data) {
        return [_c('b-media', {
          attrs: {
            "vertical-align": "center",
            "no-body": ""
          }
        }, [_c('b-media-aside', [_c('b-avatar', {
          attrs: {
            "size": "32",
            "src": data.item.image,
            "text": _vm.avatarText(data.item.name),
            "variant": "light-".concat(_vm.resolveUserRoleVariant(data.item.role))
          }
        })], 1), _c('b-media-body', {
          staticClass: "align-self-center"
        }, [_c('b-link', {
          staticClass: "font-weight-bold d-block text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.name) + " ")])], 1)], 1)];
      }
    }, {
      key: "cell(image)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.item.image,
            "rounded": "",
            "size": "4rem"
          }
        })];
      }
    }, {
      key: "cell(avatar)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.item.avatar,
            "rounded": "",
            "size": "4rem"
          }
        })];
      }
    }, {
      key: "cell(logo)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.item.logo,
            "rounded": "",
            "size": "4rem"
          }
        })];
      }
    }, {
      key: "cell(icon)",
      fn: function fn(data) {
        return [_c('b-avatar', {
          attrs: {
            "src": data.item.icon,
            "rounded": "",
            "size": "4rem"
          }
        })];
      }
    }, {
      key: "cell(role)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          class: "text-".concat(_vm.resolveUserRoleVariant(data.item.role)),
          attrs: {
            "icon": _vm.resolveUserRoleIcon(data.item.role),
            "size": "18"
          }
        }), _c('span', {
          staticClass: "align-text-top text-capitalize"
        }, [_vm._v(_vm._s(data.item.role))])], 1)];
      }
    }, {
      key: "cell(status)",
      fn: function fn(data) {
        return [_c('b-badge', {
          staticClass: "text-capitalize",
          attrs: {
            "pill": "",
            "variant": "light-".concat(_vm.resolveUserStatusVariant(data.item.status))
          }
        }, [_vm._v(" " + _vm._s(data.item.status_value) + " ")])];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [data.item.actions && data.item.actions.can_change_status && _vm.toggleStatus ? _c('div', {
          staticClass: "position-relative"
        }, [_c('b-form-checkbox', {
          attrs: {
            "checked": "true",
            "value": "active",
            "unchecked-value": "inactive",
            "name": "check-button",
            "switch": ""
          },
          model: {
            value: data.item.status,
            callback: function callback($$v) {
              _vm.$set(data.item, "status", $$v);
            },
            expression: "data.item.status"
          }
        }), _c('div', {
          staticClass: "switch-layer",
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.changeStatus($event, data.item);
            }
          }
        })], 1) : _vm._e(), data.item.actions && data.item.actions.can_update && _vm.canUpdate ? _c('b-link', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-primary",
            modifiers: {
              "hover": true,
              "v-primary": true
            }
          }],
          staticClass: "ml-1",
          attrs: {
            "title": "Edit",
            "to": {
              name: _vm.editRoute,
              params: {
                resourceId: data.item.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "EditIcon",
            "size": "16"
          }
        })], 1) : _vm._e(), data.item.actions && data.item.actions.can_delete && _vm.canDelete ? _c('b-link', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.v-danger",
            modifiers: {
              "hover": true,
              "v-danger": true
            }
          }],
          staticClass: "ml-1",
          attrs: {
            "title": "Delete"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.showDeleteModal(data.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "align-middle text-body",
          attrs: {
            "icon": "TrashIcon",
            "size": "16"
          }
        })], 1) : _vm._e(), _vm.slotCustomActions ? _vm._t("custom_actions", null, {
          "item": data.item,
          "currentActions": _vm.currentActions
        }) : _vm._e()], 2)];
      }
    }], null, true)
  }), _c('div', {
    staticClass: "mx-2 mb-2"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-start",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('span', {
    staticClass: "text-muted"
  }, [_vm._v("Showing " + _vm._s(_vm.dataMeta.from) + " to " + _vm._s(_vm.dataMeta.to) + " of " + _vm._s(_vm.dataMeta.of) + " entries")])]), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-center justify-content-sm-end",
    attrs: {
      "cols": "12",
      "sm": "6"
    }
  }, [_c('b-pagination', {
    staticClass: "mb-0 mt-1 mt-sm-0",
    attrs: {
      "total-rows": _vm.total,
      "per-page": _vm.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1), _c('b-modal', {
    ref: "delete-modal",
    attrs: {
      "id": "delete-modal",
      "ok-variant": "danger",
      "modal-class": "modal-danger",
      "centered": "",
      "title": "Confirmation"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "danger"
          },
          on: {
            "click": _vm.confirmDelete
          }
        }, [_vm._v(" Confirm ")])];
      }
    }])
  }, [_c('b-card-text', [_vm._v(" Are you sure you want to delete this item? ")])], 1), _c('b-modal', {
    ref: "change-status-modal",
    attrs: {
      "id": "change-status-modal",
      "modal-class": "modal-primary",
      "centered": "",
      "title": "Confirmation"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref2) {
        var cancel = _ref2.cancel;
        return [_c('b-button', {
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.confirmChangeStatus
          }
        }, [_vm._v(" Confirm ")])];
      }
    }])
  }, [_c('b-card-text', [_vm._v(" Are you sure you want to change status? ")])], 1), _c('b-modal', {
    ref: "export-options-modal",
    attrs: {
      "id": "export-options-modal",
      "modal-class": "modal-primary",
      "centered": "",
      "title": "Export Options",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref3) {
        var cancel = _ref3.cancel;
        return [_c('b-button', {
          on: {
            "click": function click($event) {
              return cancel();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": _vm.exportOptions
          }
        }, [_vm._v(" Export ")])];
      }
    }])
  }, [_c('b-card-text', [_vm._v(" Please select the export columns? "), _c('b-form-group', {
    staticClass: "table-fields-checkbox",
    attrs: {
      "label": ""
    }
  }, [_c('b-form-checkbox-group', {
    staticClass: "demo-inline-spacing",
    attrs: {
      "options": _vm.tableFields,
      "name": "flavour-2a",
      "stacked": ""
    },
    model: {
      value: _vm.selectedColumns,
      callback: function callback($$v) {
        _vm.selectedColumns = $$v;
      },
      expression: "selectedColumns"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }