<template>
    <div>
        <b-form-group :label="label">
            <b-form-datepicker
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                @context="onContext"
                :value="value"
                :formatted-value="value"
            />
        </b-form-group>
    </div>
</template>
<script>
export default {
    props: ['label', 'value'],
    methods: {
        onContext(ctx) {
            this.$emit('input', ctx.selectedYMD)
        }
    },
}
</script>
