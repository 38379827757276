var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('ul', {
    staticClass: "languages-tabs"
  }, _vm._l(_vm.languages, function (item, k, idx) {
    return _c('li', {
      key: idx,
      class: [_vm.selectedTab === idx ? 'active' : '', _vm.getFieldError("".concat(_vm.name, ".").concat(k)) ? 'danger' : ''],
      on: {
        "click": function click($event) {
          return _vm.selectTab(idx);
        }
      }
    }, [_vm._v(" " + _vm._s(item) + " ")]);
  }), 0), _vm._l(_vm.languages, function (item, k, idx) {
    return _vm._t("lang".concat(idx), null, {
      "selectedTab": _vm.selectedTab
    });
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }