export default {
  methods: {
    afterSubmit(message, routeName, params = {}) {
      this.$bvToast.toast(message, {
        title: 'Success',
        variant: 'success',
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      })
      setTimeout(() => this.$router.push({ name: routeName, params }), 1000)
    },
    moduleFilter(searchFields, $val) {
      let $filters = ''
      const $fields = []
      Object.keys(searchFields)
        .forEach(x => {
          if ($fields.indexOf(x) < 0) {
            $fields.push(x)
          }
        })
      $fields.forEach(x => {
        $filters += $val[x] ? `&${x}=${$val[x]}` : ''
      })
      return $filters
    },

    getSlug(title) {
      let slug = ''
      // Change to lower case
      const titleLower = title.toLowerCase()

      // Trim the last whitespace
      slug = titleLower.replace(/\s*$/g, '')
      // Change whitespace to "-"
      slug = slug.replace(/\s+/g, '-')

      // regex to remove symbols
      slug = slug.replace(/[!@#\$%\^\&*\)\(+=._?<>]/g, '')

      return slug
    },
    randomNumber(min, max) {
      return Math.floor(Math.random() * (max - min)) + min
    },
    TitleKey(text) {
      return `${text.replace(/_/g, ' ')}`
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      // && charCode !== 46
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    isDecimal(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    },
    displayNumber(number) {
      return parseFloat(number).toFixed(2)
    },
  },
}
