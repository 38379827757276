var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    attrs: {
      "label": _vm.label
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "date-format-options": {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric'
      },
      "value": _vm.value,
      "formatted-value": _vm.value
    },
    on: {
      "context": _vm.onContext
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }