import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import pages from './routes/pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    }
  },
  routes: [
    {
      path: '/',
      redirect: { name: 'dashboard' },
    },
    ...pages,
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/Dashboard.vue'),
    },
    {
      path: '/pages',
      name: 'pages',
      component: () => import('@/views/pages/list.vue'),
    },
    {
      path: '/pages/add',
      name: 'add-page',
      component: () => import('@/views/pages/form.vue'),
      meta: {
        resource: 'pages',
        pageTitle: 'Add Page',
        breadcrumb: [
          {
            to: { name: 'pages' },
            text: 'Pages',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/pages/:resourceId',
      name: 'edit-page',
      component: () => import('@/views/pages/form.vue'),
      meta: {
        resource: 'pages',
        pageTitle: 'Edit Page',
        breadcrumb: [
          {
            to: { name: 'pages' },
            text: 'Pages',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/objectives',
      name: 'objectives',
      component: () => import('@/views/objectives/list.vue'),
    },
    {
      path: '/objectives/add',
      name: 'add-objective',
      component: () => import('@/views/objectives/form.vue'),
      meta: {
        resource: 'objectives',
        pageTitle: 'Add Objective',
        breadcrumb: [
          {
            to: { name: 'objectives' },
            text: 'Objectives',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/objectives/:resourceId',
      name: 'edit-objective',
      component: () => import('@/views/objectives/form.vue'),
      meta: {
        resource: 'objectives',
        pageTitle: 'Edit Objective',
        breadcrumb: [
          {
            to: { name: 'objectives' },
            text: 'Objective',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('@/views/users/list.vue'),
    },
    {
      path: '/users/add',
      name: 'add-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Add User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/users/:resourceId',
      name: 'edit-user',
      component: () => import('@/views/users/form.vue'),
      meta: {
        resource: 'users',
        pageTitle: 'Edit User',
        breadcrumb: [
          {
            to: { name: 'users' },
            text: 'Users',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sliders',
      name: 'sliders',
      component: () => import('@/views/sliders/list.vue'),
    },
    {
      path: '/sliders/add',
      name: 'add-slider',
      component: () => import('@/views/sliders/form.vue'),
      meta: {
        resource: 'sliders',
        pageTitle: 'Add Slider',
        breadcrumb: [
          {
            to: { name: 'sliders' },
            text: 'Sliders',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sliders/:resourceId',
      name: 'edit-slider',
      component: () => import('@/views/sliders/form.vue'),
      meta: {
        resource: 'sliders',
        pageTitle: 'Edit Slider',
        breadcrumb: [
          {
            to: { name: 'sliders' },
            text: 'Sliders',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categories',
      name: 'categories',
      component: () => import('@/views/categories/list.vue'),
    },
    {
      path: '/categories/add',
      name: 'add-category',
      component: () => import('@/views/categories/form.vue'),
      meta: {
        resource: 'categories',
        pageTitle: 'Add Category',
        breadcrumb: [
          {
            to: { name: 'categories' },
            text: 'Categories',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/categories/:resourceId',
      name: 'edit-category',
      component: () => import('@/views/categories/form.vue'),
      meta: {
        resource: 'categories',
        pageTitle: 'Edit Category',
        breadcrumb: [
          {
            to: { name: 'categories' },
            text: 'Categories',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/testimonials',
      name: 'testimonials',
      component: () => import('@/views/testimonials/list.vue'),
    },
    {
      path: '/testimonials/add',
      name: 'add-testimonial',
      component: () => import('@/views/testimonials/form.vue'),
      meta: {
        resource: 'testimonials',
        pageTitle: 'Add Testimonial',
        breadcrumb: [
          {
            to: { name: 'testimonials' },
            text: 'Testimonials',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/testimonials/:resourceId',
      name: 'edit-testimonial',
      component: () => import('@/views/testimonials/form.vue'),
      meta: {
        resource: 'testimonials',
        pageTitle: 'Edit Testimonial',
        breadcrumb: [
          {
            to: { name: 'testimonials' },
            text: 'Testimonials',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/partners',
      name: 'partners',
      component: () => import('@/views/partners/list.vue'),
    },
    {
      path: '/partners/add',
      name: 'add-partner',
      component: () => import('@/views/partners/form.vue'),
      meta: {
        resource: 'partners',
        pageTitle: 'Add Partner',
        breadcrumb: [
          {
            to: { name: 'partners' },
            text: 'Partners',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/partners/:resourceId',
      name: 'edit-partner',
      component: () => import('@/views/partners/form.vue'),
      meta: {
        resource: 'partners',
        pageTitle: 'Edit Partner',
        breadcrumb: [
          {
            to: { name: 'partners' },
            text: 'Partners',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/features',
      name: 'features',
      component: () => import('@/views/features/list.vue'),
    },
    {
      path: '/features/add',
      name: 'add-feature',
      component: () => import('@/views/features/form.vue'),
      meta: {
        resource: 'features',
        pageTitle: 'Add Feature',
        breadcrumb: [
          {
            to: { name: 'features' },
            text: 'Features',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/features/:resourceId',
      name: 'edit-feature',
      component: () => import('@/views/features/form.vue'),
      meta: {
        resource: 'features',
        pageTitle: 'Edit Feature',
        breadcrumb: [
          {
            to: { name: 'features' },
            text: 'Features',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles',
      name: 'roles',
      component: () => import('@/views/roles/list.vue'),
    },
    {
      path: '/roles/add',
      name: 'add-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Add Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Add',
            active: true,
          },
        ],
      },
    },
    {
      path: '/roles/:resourceId',
      name: 'edit-role',
      component: () => import('@/views/roles/form.vue'),
      meta: {
        resource: 'roles',
        pageTitle: 'Edit Role',
        breadcrumb: [
          {
            to: { name: 'roles' },
            text: 'Roles',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams',
      name: 'teams',
      component: () => import('@/views/teams/list.vue'),
    },
    {
      path: '/teams/add',
      name: 'add-team',
      component: () => import('@/views/teams/form.vue'),
      meta: {
        resource: 'teams',
        pageTitle: 'Add Team',
        breadcrumb: [
          {
            to: { name: 'teams' },
            text: 'Teams',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/teams/:resourceId',
      name: 'edit-team',
      component: () => import('@/views/teams/form.vue'),
      meta: {
        resource: 'teams',
        pageTitle: 'Edit Team',
        breadcrumb: [
          {
            to: { name: 'teams' },
            text: 'Teams',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('@/views/news/list.vue'),
    },
    {
      path: '/news/add',
      name: 'add-news',
      component: () => import('@/views/news/form.vue'),
      meta: {
        resource: 'news',
        pageTitle: 'Add News',
        breadcrumb: [
          {
            to: { name: 'news' },
            text: 'News',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news/:resourceId',
      name: 'edit-news',
      component: () => import('@/views/news/form.vue'),
      meta: {
        resource: 'news',
        pageTitle: 'Edit News',
        breadcrumb: [
          {
            to: { name: 'news' },
            text: 'News',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/albums',
      name: 'albums',
      component: () => import('@/views/albums/list.vue'),
    },
    {
      path: '/albums/add',
      name: 'add-album',
      component: () => import('@/views/albums/form.vue'),
      meta: {
        resource: 'albums',
        pageTitle: 'Add Media Center',
        breadcrumb: [
          {
            to: { name: 'albums' },
            text: 'Media Center',
          },
          {
            text: 'Create',
            active: true,
          },
        ],
      },
    },
    {
      path: '/albums/:resourceId',
      name: 'edit-album',
      component: () => import('@/views/albums/form.vue'),
      meta: {
        resource: 'albums',
        pageTitle: 'Edit Media Center',
        breadcrumb: [
          {
            to: { name: 'albums' },
            text: 'Media Center',
          },
          {
            text: 'Edit',
            active: true,
          },
        ],
      },
    },
    {
      path: '/contactus',
      name: 'contactus',
      component: () => import('@/views/contacts/list.vue'),
    },
    {
      path: '/contactus/details/:id',
      name: 'contactus-details',
      component: () => import('@/views/contacts/details.vue'),
      meta: {
        resource: 'contactus',
        pageTitle: 'Contact Us Details',
        breadcrumb: [
          {
            to: { name: 'contactus' },
            text: 'Contact Us',
          },
          {
            text: 'Details',
            active: true,
          },
        ],
      },
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('@/views/settings/form.vue'),
      meta: {
        resource: 'settings',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
