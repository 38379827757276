var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    ref: "ref_title_details",
    attrs: {
      "id": "title_details_".concat(_vm.currentTime)
    }
  }, [_c('div', {
    staticClass: "repeater-form"
  }, [_c('b-row', {
    ref: "row"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('LocaleTabs', {
    attrs: {
      "errors": _vm.formErrors,
      "name": "title"
    },
    scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
      return {
        key: "lang".concat(idx),
        fn: function fn(slotScope) {
          return [_c('ValidationProvider', {
            key: idx,
            class: slotScope.selectedTab !== idx ? 'hidden' : '',
            attrs: {
              "vid": "title.".concat(k),
              "name": "".concat(i, " Title"),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref) {
                var errors = _ref.errors;
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(i, " Title")
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "placeholder": "".concat(i, " Title")
                  },
                  model: {
                    value: _vm.fields.title[k],
                    callback: function callback($$v) {
                      _vm.$set(_vm.fields.title, k, $$v);
                    },
                    expression: "fields.title[k]"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])], 1)];
              }
            }], null, true)
          })];
        }
      };
    })], null, true)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('LocaleTabs', {
    attrs: {
      "errors": _vm.formErrors,
      "name": "title"
    },
    scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
      return {
        key: "lang".concat(idx),
        fn: function fn(slotScope) {
          return [_c('ValidationProvider', {
            key: idx,
            class: slotScope.selectedTab !== idx ? 'hidden' : '',
            attrs: {
              "vid": "details.".concat(k),
              "name": "".concat(i, " Details"),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var errors = _ref2.errors;
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(i, " Details")
                  }
                }, [_c('b-form-textarea', {
                  attrs: {
                    "placeholder": "".concat(i, " Details"),
                    "rows": "5"
                  },
                  model: {
                    value: _vm.fields.details[k],
                    callback: function callback($$v) {
                      _vm.$set(_vm.fields.details, k, $$v);
                    },
                    expression: "fields.details[k]"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])], 1)];
              }
            }], null, true)
          })];
        }
      };
    })], null, true)
  })], 1), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._t("style_box")], 2)]), _c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_vm._t("delete_titleDetails")], 2)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr')])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }