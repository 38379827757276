var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('b-row', {
    ref: "row"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('LocaleTabs', {
    attrs: {
      "errors": _vm.formErrors,
      "name": "details"
    },
    scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
      return {
        key: "lang".concat(idx),
        fn: function fn(slotScope) {
          return [_c('ValidationProvider', {
            key: idx,
            class: slotScope.selectedTab !== idx ? 'hidden' : '',
            attrs: {
              "vid": "details.".concat(k),
              "name": "".concat(i, " Details"),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref) {
                var errors = _ref.errors;
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(i, " Details")
                  }
                }, [_c('editor', {
                  attrs: {
                    "api-key": _vm.tinyApiKey,
                    "init": _vm.tinyConfig
                  },
                  model: {
                    value: _vm.fields.details[k],
                    callback: function callback($$v) {
                      _vm.$set(_vm.fields.details, k, $$v);
                    },
                    expression: "fields.details[k]"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])], 1)];
              }
            }], null, true)
          })];
        }
      };
    })], null, true)
  })], 1)], 1), _c('div', {
    staticClass: "py-.5"
  }, [_c('hr')])], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }