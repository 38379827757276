<template>
  <b-col cols="12">
    <div
      :id="`title_details_${currentTime}`"
      ref="ref_title_details"
    >
      <div
        class="repeater-form"
      >
        <!-- Row Loop -->
        <b-row
          ref="row"
        >
          <b-col cols="12">
            <LocaleTabs
              :errors="formErrors"
              name="title"
            >
              <template
                v-for="(i,k,idx) in languages"
                :slot="`lang${idx}`"
                slot-scope="slotScope"
              >
                <ValidationProvider
                  :key="idx"
                  v-slot="{ errors }"
                  :class="slotScope.selectedTab!==idx?'hidden':''"
                  :vid="`title.${k}`"
                  :name="`${i} Title`"
                  rules="required"
                >
                  <b-form-group :label="`${i} Title`">
                    <b-form-input
                      v-model="fields.title[k]"
                      :placeholder="`${i} Title`"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </template>
            </LocaleTabs>
          </b-col>

          <b-col md="12">
            <LocaleTabs
              :errors="formErrors"
              name="title"
            >
              <template
                v-for="(i,k,idx) in languages"
                :slot="`lang${idx}`"
                slot-scope="slotScope"
              >
                <ValidationProvider
                  :key="idx"
                  v-slot="{ errors }"
                  :class="slotScope.selectedTab!==idx?'hidden':''"
                  :vid="`details.${k}`"
                  :name="`${i} Details`"
                  rules="required"
                >
                  <b-form-group :label="`${i} Details`">
                    <b-form-textarea
                      v-model="fields.details[k]"
                      :placeholder="`${i} Details`"
                      rows="5"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </ValidationProvider>
              </template>
            </LocaleTabs>
          </b-col>

          <b-col md="12">
            <div class="d-flex justify-content-between">
              <slot name="style_box" />
            </div>
          </b-col>

          <b-col md="12">
            <div class="d-flex justify-content-between">
              <slot name="delete_titleDetails" />
            </div>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => [],
    },
    formErrors: {
      type: Object,
      default: () => ({}),
    },
    languages: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: {},
    }
  },
  computed: {
    currentTime() {
      return new Date().getTime()
    },
  },
  watch: {
    items: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.items = newValue
      },
      deep: true,
    },
  },
  created() {
    this.fields = this.value
  },
}
</script>
