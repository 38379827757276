<template>
  <b-col cols="12">
    <div>
      <!-- Row Loop -->
      <b-row ref="row">
        <b-col
          cols="12"
          class="mb-50"
        >
          <template>
            <label>File:</label>
            <div class="d-flex align-items-start justify-content-between">
              <div class="flex-grow-1">
                <b-form-file
                  placeholder="Choose a file or drop it here..."
                  drop-placeholder="Drop file here..."
                  @input="onFileChange($event)"
                />
                <b-card-text
                  v-if="fields.file"
                  class="py-5px"
                >
                  File: <strong>{{ fields.name }}</strong>
                </b-card-text>
                <validation-provider
                  v-slot="{ errors }"
                  tag="div"
                  vid="title"
                  name="title"
                  rules="required"
                  class="mt-2"
                >
                  <b-form-group label="Title">
                    <b-form-input
                      v-model="fields.title"
                      placeholder="Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </div>
              <slot name="delete_fileRep" />
            </div>
          </template>
          <div
            v-if="loadingFile"
            class="loading-overlay"
          >
            <div class="d-flex align-items-center">
              <strong>Loading...</strong>
              <b-spinner class="ml-2" />
            </div>
          </div>
        </b-col>

        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>
import { serialize } from 'object-to-formdata'

export default {
  props: {
    value: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: () => [],
    },
  },
  data() {
    return {
      fields: {},
      loadingFile: false,
    }
  },
  watch: {
    value: {
      handler(newValue) {
        this.fields = newValue
      },
      deep: true,
    },
    fields: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true,
    },
  },
  created() {
    this.fields = this.value
  },
  methods: {
    onFileChange(e) {
      this.uploadFile(e)
    },
    uploadFile(file) {
      const form = {
        file,
        name: file.name,
      }
      const formData = serialize(form)
      this.loadingFile = true
      this.axios.post('/media-library/uploads', formData)
        .then(res => {
          this.loadingFile = false
          const { data } = res
          this.fields = {
            ...this.fields,
            name: file.name,
            file: data.original_url,
            size: data.size,
          }
        })
        .catch(() => {
          this.loadingFile = false
        })
    },
  },
}
</script>

<style lang="scss">
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.35);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
