var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_c('b-row', {
    ref: "row"
  }, [_c('b-col', {
    staticClass: "mb-50",
    attrs: {
      "lg": "2",
      "md": "3"
    }
  }, [_c('ImageUpload', {
    scopedSlots: _vm._u([{
      key: "delete_image",
      fn: function fn() {
        return [_vm._t("delete_images")];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.fields.image,
      callback: function callback($$v) {
        _vm.$set(_vm.fields, "image", $$v);
      },
      expression: "fields.image"
    }
  }), _vm._t("b_dropdown")], 2), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr')])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }