var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-form-group', {
    attrs: {
      "label": _vm.label
    }
  }, [_c('div', {
    staticClass: "custom-img dropzone dropzone-default dropzone-brand dz-clickable px-1 text-center",
    class: _vm.value ? 'show-img' : ''
  }, [_c('div', {
    staticClass: "dropzone-msg dz-message needsclick"
  }, [!_vm.loadingImage ? _c('p', {
    staticClass: "dropzone-msg-title"
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]) : _c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }), _c('span', {
    staticClass: "dropzone-msg-desc"
  }, [_vm._v(_vm._s(_vm.text))])], 1), _c('input', {
    staticClass: "input-file",
    attrs: {
      "type": "file",
      "accept": ".jpg,.jpeg,.png"
    },
    on: {
      "input": _vm.onFileChange
    }
  }), _vm.imgPreview ? _c('img', {
    staticClass: "img",
    attrs: {
      "src": _vm.imgPreview,
      "alt": "image"
    }
  }) : _vm._e(), _vm._t("delete_image")], 2)]), _vm.hint ? _c('small', {
    staticClass: "helper line-height-condensed d-block"
  }, [_vm._v(_vm._s(_vm.hint))]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }