var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "album-custom"
  }, [_vm._t("delete_images"), _c('MediaLibraryAttachment', {
    ref: "mediaComponent",
    attrs: {
      "max-items": 1,
      "name": "avatar",
      "initial-value": _vm.media,
      "headers": _vm.headers,
      "routePrefix": "media-library",
      "uploadDomain": _vm.baseURL,
      "validation-rules": {
        accept: ['image/webp'],
        maxSizeInKB: 200
      },
      "can_remove": false
    },
    on: {
      "change": function change($event) {
        return _vm.getFiles($event);
      }
    }
  }), _c('LocaleTabs', {
    staticClass: "mt-2",
    attrs: {
      "errors": _vm.formErrors,
      "name": "title"
    },
    scopedSlots: _vm._u([_vm._l(_vm.languages, function (i, k, idx) {
      return {
        key: "lang".concat(idx),
        fn: function fn(slotScope) {
          return [_c('ValidationProvider', {
            key: idx,
            class: slotScope.selectedTab !== idx ? 'hidden' : '',
            attrs: {
              "vid": "title.".concat(k),
              "name": "".concat(i, " Title"),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref) {
                var errors = _ref.errors;
                return [_c('b-form-group', {
                  attrs: {
                    "label": "".concat(i, " Title")
                  }
                }, [_c('b-form-input', {
                  attrs: {
                    "placeholder": "".concat(i, " Title")
                  },
                  model: {
                    value: _vm.form.title[k],
                    callback: function callback($$v) {
                      _vm.$set(_vm.form.title, k, $$v);
                    },
                    expression: "form.title[k]"
                  }
                }), _c('small', {
                  staticClass: "text-danger"
                }, [_vm._v(_vm._s(errors[0]))])], 1)];
              }
            }], null, true)
          })];
        }
      };
    })], null, true)
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }