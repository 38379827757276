import Vue from 'vue'
import UrlService from '@/services/url.service'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  // eslint-disable-next-line import/no-named-as-default-member
  baseURL: UrlService.baseURL(),
  // timeout: 1000,
  headers: {
    'Accept-Language': 'en',
  },
})

Vue.prototype.$http = axiosIns

export default axiosIns
