var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "type": _vm.type,
      "variant": "primary",
      "disabled": _vm.isLoading
    }
  }, [_vm.isLoading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "type": "grow"
    }
  }) : _c('span', [_vm._v(_vm._s(_vm.buttonText))])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }