<template>
  <div class="album-custom">
    <slot name="delete_images" />
    <MediaLibraryAttachment
      ref="mediaComponent"
      :max-items="1"
      name="avatar"
      :initial-value="media"
      :headers="headers"
      routePrefix="media-library"
      :uploadDomain="baseURL"
      @change="getFiles($event)"
      :validation-rules="{ accept: ['image/webp'], maxSizeInKB: 200}"
      :can_remove="false"
    />
    <LocaleTabs
      class="mt-2"
      :errors="formErrors"
      name="title"
    >
      <template
        v-for="(i,k,idx) in languages"
        :slot="`lang${idx}`"
        slot-scope="slotScope"
      >
        <ValidationProvider
          :key="idx"
          v-slot="{ errors }"
          :class="slotScope.selectedTab!==idx?'hidden':''"
          :vid="`title.${k}`"
          :name="`${i} Title`"
          rules="required"
        >
          <b-form-group :label="`${i} Title`">
            <b-form-input
              v-model="form.title[k]"
              :placeholder="`${i} Title`"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </ValidationProvider>
      </template>
    </LocaleTabs>
  </div>
</template>
<script>
import { baseURL } from '@/services/url.service'
import MediaLibraryAttachment from './media-spatie/media-library-pro-vue2-attachment/src/MediaLibraryAttachment.vue'

export default {
  components: {
    MediaLibraryAttachment,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        uuid_value: null,
        title: {
          en: null,
          ar: null,
        },
      }),
    },
    languages: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      arr2: [],
      media: null,
      baseURL: baseURL(),
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
      validationErrors: {
        avatar: 'The Avatar field is required',
      },
      imageAfterUpload: [],
      form: this.value,
      formErrors: {},
    }
  },
  watch: {
    form(newVal) {
      this.$emit('input', newVal)
    },
  },
  created() {
    this.getUuid()
  },
  mounted() {
    const media = this.arr2
    this.$refs.mediaComponent.mediaLibrary.changeState(state => {
      state.media = media
    })
  },
  methods: {
    getUuid() {
      this.media = this.form.media
      const obj = {
        attributes: this.form.media,
        clientValidationErrors: [],
        client_preview: this.form.media?.original_url,
        upload: {},
      }
      this.arr2.push(obj)
    },
    getFiles(e) {
      const arr = []
      arr.push(e)
      this.imageAfterUpload = arr
      const imageAfterUpload = this.imageAfterUpload[0]
      // eslint-disable-next-line prefer-destructuring
      this.form.uuid_value = Object.keys(imageAfterUpload)[0]
    },
  },
}
</script>

<style>
.album-custom{
  position: relative;
  padding-top: 30px;
}
</style>
