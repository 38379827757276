<template>
  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <router-link
                v-if="canCreate"
                :to="addRoute"
                class="btn btn-primary"
              >
                <span class="text-nowrap text-capitalize">{{ addLabel }}</span>
              </router-link>
              <b-dropdown
                v-if="currentActions.can_import || currentActions.can_export"
                :right="!$store.state.appConfig.isRTL"
                class="ml-1"
                text="Tools"
                variant="primary"
              >
                <b-dropdown-item v-if="currentActions.can_import">
                  <span class="align-middle ml-50">Import</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="currentActions.can_export"
                  @click.prevent="exportTable"
                >
                  <span class="align-middle ml-50">Export</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="currentActions.can_export"
                  @click.prevent="openExportOptions"
                >
                  <span class="align-middle ml-50">Export Options</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="shownHeaders"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media
            vertical-align="center"
            no-body
          >
            <b-media-aside>
              <b-avatar
                size="32"
                :src="data.item.image"
                :text="avatarText(data.item.name)"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
              />
            </b-media-aside>
            <b-media-body class="align-self-center">
              <b-link
                class="font-weight-bold d-block text-nowrap"
              >
                {{ data.item.name }}
              </b-link>
            </b-media-body>
          </b-media>
        </template>

        <template #cell(image)="data">
          <b-avatar
            :src="data.item.image"
            rounded
            size="4rem"
          />
        </template>

        <template #cell(avatar)="data">
          <b-avatar
            :src="data.item.avatar"
            rounded
            size="4rem"
          />
        </template>

        <template #cell(logo)="data">
          <b-avatar
            :src="data.item.logo"
            rounded
            size="4rem"
          />
        </template>

        <template #cell(icon)="data">
          <b-avatar
            :src="data.item.icon"
            rounded
            size="4rem"
          />
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveUserRoleIcon(data.item.role)"
              size="18"
              class="mr-50"
              :class="`text-${resolveUserRoleVariant(data.item.role)}`"
            />
            <span class="align-text-top text-capitalize">{{ data.item.role }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveUserStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status_value }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="d-flex align-items-center">
            <div
              v-if="(data.item.actions && data.item.actions.can_change_status) && toggleStatus"
              class="position-relative"
            >
              <b-form-checkbox
                v-model="data.item.status"
                checked="true"
                value="active"
                unchecked-value="inactive"
                name="check-button"
                switch
              />
              <div
                class="switch-layer"
                @click.prevent="changeStatus($event, data.item)"
              />
            </div>
            <b-link
              v-if="(data.item.actions && data.item.actions.can_update) && canUpdate"
              v-b-tooltip.hover.v-primary
              title="Edit"
              :to="{ name: editRoute, params: { resourceId: data.item.id } }"
              class="ml-1"
            >
              <feather-icon
                icon="EditIcon"
                size="16"
                class="align-middle text-body"
              />
            </b-link>
            <b-link
              v-if="(data.item.actions && data.item.actions.can_delete) && canDelete"
              v-b-tooltip.hover.v-danger
              title="Delete"
              class="ml-1"
              @click.prevent="showDeleteModal(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
                size="16"
                class="align-middle text-body"
              />
            </b-link>
            <slot
              v-if="slotCustomActions"
              name="custom_actions"
              :item="data.item"
              :currentActions="currentActions"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{
              dataMeta.of
            }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
      id="delete-modal"
      ref="delete-modal"
      ok-variant="danger"
      modal-class="modal-danger"
      centered
      title="Confirmation"
    >
      <b-card-text>
        Are you sure you want to delete this item?
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button
          variant="danger"
          @click="confirmDelete"
        >
          Confirm
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="change-status-modal"
      ref="change-status-modal"
      modal-class="modal-primary"
      centered
      title="Confirmation"
    >
      <b-card-text>
        Are you sure you want to change status?
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="confirmChangeStatus"
        >
          Confirm
        </b-button>
      </template>
    </b-modal>

    <!-- modal -->
    <b-modal
      id="export-options-modal"
      ref="export-options-modal"
      modal-class="modal-primary"
      centered
      title="Export Options"
      size="lg"
    >
      <b-card-text>
        Please select the export columns?

        <b-form-group
          label=""
          class="table-fields-checkbox"
        >
          <b-form-checkbox-group
            v-model="selectedColumns"
            :options="tableFields"
            name="flavour-2a"
            class="demo-inline-spacing"
            stacked
          />
        </b-form-group>
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          Cancel
        </b-button>
        <b-button
          variant="primary"
          @click="exportOptions"
        >
          Export
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'

export default {
  name: 'TableIndex',
  props: {
    sortBy: {
      type: String,
      default: 'id',
    },
    sortDir: {
      type: Boolean,
      default: true,
    },
    resourceName: {
      type: String,
      required: true,
    },
    singularName: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    addRoute: {
      type: Object,
      default() {
        return {
          name: `add-${this.singularName.toLocaleLowerCase()}`,
        }
      },
    },
    editRoute: {
      type: String,
      default() {
        return `edit-${this.singularName.toLocaleLowerCase()}`
      },
    },
    filterFields: {
      type: Object,
      default() {
        return {}
      },
    },
    statuses: {
      type: Array,
      default() {
        return ['active', 'inactive']
      },
    },
    toggleStatus: {
      type: Boolean,
      default() {
        return true
      },
    },
    hasActions: {
      type: Boolean,
      default() {
        return true
      },
    },
    canCreate: {
      type: Boolean,
      default() {
        return true
      },
    },
    canUpdate: {
      type: Boolean,
      default() {
        return true
      },
    },
    canDelete: {
      type: Boolean,
      default() {
        return true
      },
    },
    resourceDelete: {
      type: String,
      default() {
        return ''
      },
    },
    resourceExport: {
      type: String,
      default() {
        return ''
      },
    },
    reload: {
      type: Number,
      default() {
        return 0
      },
    },
  },
  setup() {
    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveUserRoleVariant = role => {
      if (role === 'subscriber') return 'primary'
      if (role === 'author') return 'warning'
      if (role === 'maintainer') return 'success'
      if (role === 'editor') return 'info'
      if (role === 'admin') return 'danger'
      return 'primary'
    }

    const resolveUserRoleIcon = role => {
      if (role === 'subscriber') return 'UserIcon'
      if (role === 'author') return 'SettingsIcon'
      if (role === 'maintainer') return 'DatabaseIcon'
      if (role === 'editor') return 'Edit2Icon'
      if (role === 'admin') return 'ServerIcon'
      return 'UserIcon'
    }

    const resolveUserStatusVariant = status => {
      if (status === 'pending') return 'warning'
      if (status === 'active') return 'success'
      if (status === 'inactive') return 'secondary'
      return 'primary'
    }

    return {
      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
    }
  },
  data() {
    return {
      perPage: 10,
      total: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      isAddNewUserSidebarActive: false,
      items: [],
      selectedItem: null,
      currentActions: {
        can_export: false,
        can_import: false,
        can_log: false,
      },
      headersData: [],
      isSortDirDesc: this.sortDir,
      tableColumns: [],
      selectedColumns: [],
    }
  },
  computed: {
    dataMeta() {
      return {
        from: (this.currentPage - 1) * this.perPage + 1,
        to: this.currentPage * this.perPage > this.total ? this.total : this.currentPage * this.perPage,
        of: this.total,
      }
    },
    addLabel() {
      return `Add ${this.singularName.replace(/-/g, ' ')}`
    },
    statusLabels() {
      return {
        active: 'Active',
        inactive: 'In-Active',
      }
    },
    shownHeaders() {
      const headers = this.headersData
      headers.forEach((part, index) => {
        if (['id'].includes(part.key)) {
          headers[index] = {
            ...part,
            class: 'col-last',
          }
        }
        if (['status', 'users_count', 'image', 'avatar', 'logo', 'icon'].includes(part.key)) {
          headers[index] = {
            ...part,
            class: 'col-w-100',
          }
        }
        if (part.is_array) {
          const $idx = part.label.split('.')[1]
          const $key = part.label.split('.')[2]
          headers[index] = {
            ...part,
            label: this.rowDetails.length ? this.rowDetails[$idx][$key] : '',
          }
        }
      }, headers)
      if (this.hasActions) {
        headers.push({
          key: 'actions',
          label: 'Actions',
          class: 'col-last',
        })
      }
      return headers
    },
    slotCustomActions() {
      return this.$scopedSlots.custom_actions
    },
    rowDetails() {
      return this.items[0]?.details ?? []
    },
    tableFields() {
      const $array = []
      Object.keys(this.tableColumns)
        .forEach(x => (
          $array.push({
            text: this.TitleKey(this.tableColumns[x]),
            value: x,
          })
        ))
      return $array
    },
  },
  watch: {
    currentPage() {
      this.getData()
    },
    perPage() {
      this.getData()
    },
    searchQuery() {
      this.getData()
    },
    reload: {
      deep: true,
      handler($val) {
        if ($val > 0) {
          this.getData()
        }
      },
    },
    filterFields: {
      deep: true,
      handler() {
        this.getData()
      },
    },
  },
  created() {
    this.getData()
    // this.loadExportOptions()
  },
  methods: {
    hasKey(object, name) {
      return _.has(object, name)
    },
    getData() {
      return new Promise((resolve, reject) => {
        const params = {
          page: this.currentPage,
          per_page: this.perPage,
          sort_by: this.sortBy,
          sort_dir: this.isSortDirDesc ? 'DESC' : 'ASC',
          search: this.searchQuery,
          ...this.filterFields,
        }
        this.axios
          .get(`/${this.resourceName}`, { params })
          .then(response => {
            this.items = response.data.data
            this.total = response.data.meta.total
            this.currentActions = {
              ...this.currentActions,
              ...response.data.actions,
            }
            this.headersData = response.data.header
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },
    showDeleteModal(item) {
      this.selectedItem = item
      this.$refs['delete-modal'].show()
    },
    confirmDelete() {
      const item = this.selectedItem
      const route = this.resourceDelete ? `/${this.resourceDelete}/${this.selectedItem.id}` : `/${this.resourceName}/${this.selectedItem.id}`
      this.axios
        .delete(route)
        .then(res => {
          this.$refs['delete-modal'].hide()
          const $idx = this.items.indexOf(item)
          this.items.splice($idx, 1)
          this.selectedItem = null
          this.$bvToast.toast(res.data.message, {
            title: 'Success',
            variant: 'success',
            solid: true,
            autoHideDelay: 3000,
            appendToast: true,
          })
        })
    },
    exportAction(fields) {
      const resourceExport = this.resourceExport ? `${this.resourceExport}` : `${this.resourceName}`
      let route = `/exports/${resourceExport}`
      const searchFields = {}
      Object.keys(this.filterFields).forEach(key => {
        if (this.filterFields[key] !== null) {
          searchFields[key] = this.filterFields[key]
        }
      })
      const $params = new URLSearchParams(searchFields).toString()
      if (fields.length) {
        let $fields = ''
        fields.forEach((x, i) => {
          $fields += `${i > 0 ? '&' : ''}fields[]=${x}`
        })
        route += `?${$fields}&${$params}`
      } else {
        route += `?${$params}`
      }
      this.axios
        .get(route)
        .then(res => {
          this.$bvToast.toast(res.data?.message, {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.$refs['export-options-modal'].hide()
          this.selectedColumns = []
          if (res.data.data.file) {
            window.open(res.data.data.file, '_blank')
              .focus()
          }
        })
        .catch(error => {
          this.$bvToast.toast(error.response?.data?.message, {
            title: 'Error',
            variant: 'danger',
            solid: true,
          })
        })
    },
    exportTable() {
      this.exportAction([])
    },
    exportOptions() {
      this.exportAction(this.selectedColumns)
    },
    changeStatus($e, item) {
      this.$refs['change-status-modal'].show()
      this.selectedItem = item
    },
    confirmChangeStatus() {
      this.$refs['change-status-modal'].hide()
      const $item = this.selectedItem
      const $idx = this.items.indexOf($item)
      const statusIdx = this.statuses.indexOf($item.status) === 0 ? 1 : 0
      const toStatus = this.statuses[statusIdx]
      const $form = {
        status: toStatus,
      }
      const $link = `/${this.resourceName}/${$item.id}/status`
      this.axios
        .patch($link, $form)
        .then(res => {
          this.$bvToast.toast(res.data.message, {
            title: 'Success',
            variant: 'success',
            solid: true,
          })
          this.items[$idx].status = toStatus
          this.items[$idx].status_value = this.statusLabels[toStatus]
          this.selectedItem = null
        })
    },
    // loadExportOptions() {
    //   const resourceExport = this.resourceExport ? `${this.resourceExport}` : `${this.resourceName}`
    //   const route = `/exports/${resourceExport}/options`
    //   this.axios
    //     .get(route)
    //     .then(res => {
    //       this.tableColumns = res.data.data.fields
    //     })
    // },
    openExportOptions() {
      this.$refs['export-options-modal'].show()
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.col-last {
  width: 1px;
}

.col-w-100 {
  width: 200px;
}

.switch-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
}

[dir] .table thead th {
  vertical-align: middle;
}

.table-fields-checkbox {
  .custom-control-label {
    text-transform: capitalize;
  }

  .custom-control.custom-checkbox {
    width: 30%;
  }
}
</style>
