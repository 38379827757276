<template>
  <b-col cols="12">
    <div>
      <!-- Row Loop -->
      <b-row ref="row">
        <b-col cols="12">
          <hr>
        </b-col>
        <b-col>
          <ImageUpload v-model="fields.image" />
          <slot name="b_dropdown"></slot>
          <div class="mb-2"></div>
        </b-col>

        <b-col cols="12">
          <LocaleTabs
            :errors="formErrors"
            name="details"
          >
            <template
              v-for="(i,k,idx) in languages"
              :slot="`lang${idx}`"
              slot-scope="slotScope"
            >
              <ValidationProvider
                :key="idx"
                v-slot="{ errors }"
                :class="slotScope.selectedTab!==idx?'hidden':''"
                :vid="`details.${k}`"
                :name="`${i} Details`"
                rules="required"
              >
                <b-form-group :label="`${i} Details`">
                  <editor
                    v-model="fields.details[k]"
                    :api-key="tinyApiKey"
                    :init="tinyConfig"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>
            </template>
          </LocaleTabs>
        </b-col>
      </b-row>

      <div class="d-flex justify-content-between">
        <slot name="delete_imageEditor" />
      </div>

      <div class="py-.5">
        <hr>
      </div>
    </div>
  </b-col>
</template>

<script>
import TinyService from '@/services/tiny.service'

export default {
  props: {
    value: {
      type: Object,
      default: () => [],
    },
    formErrors: {
      type: Object,
      default: () => ({}),
    },
    languages: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: {},
    }
  },
  computed: {
    tinyApiKey() {
      // eslint-disable-next-line import/no-named-as-default-member
      return TinyService.apiKey()
    },
    tinyConfig() {
      // eslint-disable-next-line import/no-named-as-default-member
      return TinyService.getConfig()
    },
  },
  watch: {
    fields: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.fields = newValue
      },
      deep: true,
    },
  },
  created() {
    this.fields = this.value
  },
}
</script>
