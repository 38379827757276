import Vue from 'vue'
import TableIndex from '@/components/tableIndex.vue'
import LocaleTabs from '@/components/LocaleTabs.vue'
import DatePicker from '@/components/datePicker.vue'
import ImagePreview from '@/components/ImagePreview.vue'
import VideoPreview from '@/components/VideoPreview.vue'
import Editor from '@tinymce/tinymce-vue'
import ImageUpload from '@/components/ImageUpload.vue'
import TitleDetails from '@/components/repeaters/TitleDetails.vue'
import ImageRep from '@/components/repeaters/ImageRep.vue'
import ImageEditor from '@/components/repeaters/ImageEditor.vue'
import FileRep from '@/components/repeaters/FileRep.vue'
import LoadingButton from '@/components/LoadingButton.vue'
import SingleEditor from '@/components/repeaters/SingleEditor.vue'
import AlbumImageNew from '@/components/AlbumImageNew.vue'
Vue.component(TableIndex.name, TableIndex)
Vue.component('LocaleTabs', LocaleTabs)
Vue.component('DatePicker', DatePicker)
Vue.component('ImagePreview', ImagePreview)
Vue.component('VideoPreview', VideoPreview)
Vue.component('Editor', Editor)
Vue.component('ImageUpload', ImageUpload)
Vue.component('TitleDetails', TitleDetails)
Vue.component('SingleEditor', SingleEditor)

Vue.component('AlbumImageNew', AlbumImageNew)

Vue.component('ImageRep', ImageRep)
Vue.component('ImageEditor', ImageEditor)
Vue.component('FileRep', FileRep)
Vue.component('LoadingButton', LoadingButton)
