<template>
  <b-col cols="12">
    <div>
      <!-- Row Loop -->
      <b-row ref="row">
        <b-col
            lg="2"
            md="3"
            class="mb-50"
        >
          <ImageUpload v-model="fields.image">
            <template v-slot:delete_image>
              <slot name="delete_images" />
            </template>
          </ImageUpload>
          <slot name="b_dropdown"></slot>
        </b-col>

        <b-col cols="12">
          <hr>
        </b-col>
      </b-row>
    </div>
  </b-col>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => [],
    },
    formErrors: {
      type: Object,
      default: () => ({}),
    },
    languages: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      fields: {},
    }
  },
  watch: {
    items: {
      handler(newValue) {
        this.$emit('input', newValue)
      },
      deep: true,
    },
    value: {
      handler(newValue) {
        this.fields = newValue
      },
      deep: true,
    },
  },
  created() {
    this.fields = this.value
  },
}
</script>
