import {serialize} from "object-to-formdata";
import axios from '@axios'

export const apiKey = () => 'mharyr0adm153xb3byihhlnti9i92y4jlp7nifevrayyoird'

export const getConfig = () => ({

  skin: 'oxide-dark',
  content_css: 'dark',
  height: 420,
  menubar: false,
  plugins: [
    'advlist autolink lists link image charmap print preview anchor',
    'searchreplace visualblocks code fullscreen',
    'insertdatetime media table paste code help wordcount',
    'textcolor',
    'image',
  ],
  toolbar:
      'undo redo | formatselect | bold italic backcolor forecolor | \
      alignleft aligncenter alignright alignjustify | \
      image link table | \
      bullist numlist outdent indent | removeformat | help',
  image_title: false,
  image_description: false,
  automatic_uploads: true,
  file_picker_types: 'image',
  images_reuse_filename: true,
  image_class_list: [
    { title: 'Image Class', value: 'image_editor_class' },
  ],
  images_upload_handler: function (blobInfo, success, failure) {
    try {
      // Send the image to your Laravel backend using an API

      const formData = serialize(blobInfo.blob())

      formData.append('file' , blobInfo.blob() )
      formData.delete('undefined')
      axios.post('/media-library/uploads', formData).then(res => {
        const { data } = res
        success(data.original);
      })

    } catch (error) {
      failure('Error uploading image');
    }
  },

  // file_picker_callback: function (cb, value, meta) {
  //   let input = document.createElement('input')
  //   input.setAttribute('type', 'file')
  //   input.setAttribute('accept', 'image/*')
  //   /*
  //     Note: In modern browsers input[type="file"] is functional without
  //     even adding it to the DOM, but that might not be the case in some older
  //     or quirky browsers like IE, so you might want to add it to the DOM
  //     just in case, and visually hide it. And do not forget do remove it
  //     once you do not need it anymore.
  //   */
  //
  //   input.onchange = function () {
  //     let file = this.files[0]
  //     const formData = serialize(file)
  //     this.loadingImage = true
  //     formData.append('file' ,file )
  //     formData.delete('undefined')
  //     axios.post('/media-library/uploads', formData).then(res => {
  //       this.loadingImage = false
  //       const { data } = res
  //
  //       this.imgPreview = data.original_url
  //       this.$emit('input', data.original_url)
  //     })
  //
  //         .catch(() => {
  //           this.loadingImage = false
  //         })
  //
  //     let kb = file.size / 1024
  //     if (kb > 100) {
  //       if (document.querySelector('.image-error-editor')) {
  //         document.querySelector('.image-error-editor').remove()
  //       }
  //       const el = document.querySelector('.tox .tox-form__controls-h-stack')
  //       const span = document.createElement('span')
  //       span.style.color = '#be6e6e'
  //       span.style.fontSize = '14px'
  //       span.style.marginTop = '4px'
  //       span.style.display = 'block'
  //       span.setAttribute('class', 'image-error-editor')
  //       const msg = 'Image size must less than 100KB'
  //       span.textContent = msg
  //       el.after(span)
  //     } else {
  //       // if (document.querySelector('.image-error-editor')) {
  //       //   document.querySelector('.image-error-editor').remove()
  //       // }
  //       // let reader = new FileReader()
  //       // reader.onload = function () {
  //       //   /*
  //       //     Note: Now we need to register the blob in TinyMCEs image blob
  //       //     registry. In the next release this part hopefully won't be
  //       //     necessary, as we are looking to handle it internally.
  //       //   */
  //       //   let id = 'blobid' + (new Date()).getTime()
  //       //   let blobCache = tinymce.activeEditor.editorUpload.blobCache
  //       //   let base64 = reader.result.split(',')[1]
  //       //   let blobInfo = blobCache.create(id, file, base64)
  //       //   blobCache.add(blobInfo)
  //       //
  //       //   /* call the callback and populate the Title field with the file name */
  //       //   cb(blobInfo.blobUri(), { title: file.name })
  //       // }
  //       // reader.readAsDataURL(file)
  //     }
  //   }
  //   input.click()
  // },
})

export default { apiKey, getConfig }
