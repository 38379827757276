import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import lodash from 'lodash'
import {
    ValidationObserver, ValidationProvider, extend, localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import ar from 'vee-validate/dist/locale/ar.json'
import en from 'vee-validate/dist/locale/en.json'
import App from './App.vue'
import store from './store'
import router from './router'
import "./assets/styles.css"
// Global Components
import './global-components'
import sharedMixin from './mixins/shared'
import './plugins/bootstrap'
import './plugins/draggable'
import vSelect from 'vue-select'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// Custom Components
import './custom-components'

import axiosPlugin from './plugins/axiosPlugin'

Object.keys(rules)
  .forEach(rule => {
      extend(rule, {
          ...rules[rule], // copies rule configuration
          // message: messages[rule] // assign message
      })
  })
localize({
    en: {
        messages: en.messages,
    },
    ar: {
        messages: ar.messages,
    },
})
extend('url', {
    validate: (value) => {
        let strongRegex = new RegExp(
          /^(http(s)?:\/\/)(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:\d{1,5})?(\/.*)?$/
        )
        return strongRegex.test(value)
    },
    message: 'The field is not a valid URL',
})

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('v-select', vSelect)
Vue.use(axiosPlugin)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.use(lodash)

Vue.mixin(sharedMixin)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
